<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Playbook <span>Smart Categories</span></h1>
                <button class="close_btn left_out" type="button" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="section_wpr">
                    <h3>
                        <span></span>
                        <button type="button" class="add_btn  ml-auto" @click="addCategory = true"><i class="fas fa-plus"></i>Add New</button>
                    </h3>
                </div>
                <div class="result_wpr new small m-0">
                    <div class="actions mt-2">
                        <ul class="left">
                            <li @click="togglePerPageFilter()" class="optionDrops sort_list" v-click-outside="closePerPageDropdown">
                                Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="categories-per-page-filter" />
                            </li>
                        </ul>
                        <ul class="right">
                            <li class="search_area">
                              <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                              <button class="search_btn"><i class="fas fa-search"></i></button>
                            </li>
                        </ul>
                    </div>
                    <div v-if="lineLoader"><line-loader /></div>
                    <table class="show_header standard">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Playbooks</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-click-outside="closeOptionsDropdown">
                            <tr v-for="(category, f) of playbookCategories.data" :key="f">
                                <td>{{ playbookCategories.from + f }}</td>
                                <td>
                                    <h4>{{ category.name }}</h4>
                                </td>
                                <td>
                                    <button class="success"  v-for="(playbook, p) of category.playbooks" :key="p">{{ playbook.title }}</button>
                                </td>
                                <td class="action">
                                    <div class="dropdown" @click="handleOptionsDropdown(category.id)">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <div class="drp_wrapper" v-if="optionsDropdown == category.id">
                                            <ul>
                                                <li @click="handleEditCategory(category)">Edit</li>
                                                <li @click="handleDeleteCategory(category.id)">Delete</li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination" v-show="playbookCategories.total">
                    <pagination v-model="params.page" :pages="playbookCategories.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
                <div v-if="addCategory" class="details_wpr">
                    <div class="cardItem_details">
                        <div class="header">
                            <button class="close_btn" type="button" @click="addCategory = false"><i class="fas fa-chevron-down"></i></button>
                            <h2>Add Smart Categories</h2>
                        </div>
                        <Form @submit="handleCreateCategory" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }">
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Category Name</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" v-model="form.name" name="name" placeholder="ex: Vegetarian" rules="required" />
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr mt-5">
                                <button :disabled="loader" type="button" class="btn cancel_btn" @click="addCategory = false">Cancel</button>
                                <button :disabled="loader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
    const Pagination = defineAsyncComponent(() => import('@hennge/vue3-pagination'))
    const LineLoader = defineAsyncComponent(() => import('@/components/LineLoader'))

    import Swal from 'sweetalert2'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Playbook Smart Category',

        data () {
            return {
                params: {
                    per_page: 5,
                    search: '',
                    page: 1,
                },
                addCategory: false,
                isTyping: false,
                form: {
                    id: '',
                    name: '',
                },
                optionsDropdown: 0,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            PageFilter,
            Pagination,
            LineLoader,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.resetForm();

                    setTimeout(function () {
                        vm.getPlaybookCategories(vm.params);
                    }, 1000);
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getPlaybookCategories(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getPlaybookCategories(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getPlaybookCategories(vm.params);
                        }
                    }
                }
            },
        },

        computed: mapState({
            lineLoader: state => state.playbookModule.playbookQuestionLoader,
            playbookCategories: state => state.playbookModule.playbookCategories,
            loader: state => state.playbookModule.categorySaveLoader,
        }),

        methods: {
            ...mapActions({
                createPlaybook: 'playbookModule/createPlaybook',
                getPlaybookCategories: 'playbookModule/getPlaybookCategories',
                createPlaybookCategory: 'playbookModule/createPlaybookCategory',
                deleteCategory: 'playbookModule/deleteCategory',
            }),

            closeModal () {
                const vm = this;

                vm.addCategory = false;
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    id: '', 
                    name: '',
                };

                vm.params = {
                    per_page: 5,
                    search: '',
                    page: 1,
                };

                vm.addCategory = false;
            },

            toggleOption (e) {
                let element = e.currentTarget;
                let trigEl = element.querySelector('.drp_wrapper');
                let trigAll = element.closest('tbody').querySelectorAll('.drp_wrapper');

                if (trigEl.classList.contains('active')) {
                    trigEl.classList.remove('active');
                } else {
                    for(let i = 0; i < trigAll.length; i++){
                        trigAll[i].classList.remove('active');
                    }

                    trigEl.classList.add('active');
                }
            },

            handleCreateCategory (form, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;

                vm.createPlaybookCategory(vm.form).then((result) => {
                    if (result) {
                        vm.resetForm();
                        vm.getPlaybookCategories(vm.params);
                    }
                });
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['categories-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['categories-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handleEditCategory (field) {
                const vm = this;

                vm.form.id     = field.id;
                vm.form.name   = field.name;
                vm.addCategory = true;
            },

            handleDeleteCategory (id) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This will remove category from playbooks and you will not be able to recover this category!`);

                options.preConfirm = () => {
                                          return vm.deleteCategory({ id: id}).then((result) => {
                                              if (result) {
                                                  vm.resetForm();
                                                  vm.getPlaybookCategories(vm.params);
                                              }
                                          });
                                      };

                Swal.fire(options);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getPlaybookCategories(vm.params);
            },

            closeOptionsDropdown() {
                const vm = this;

                vm.optionsDropdown = 0;
            },

            handleOptionsDropdown (value) {
                const vm = this;

                vm.optionsDropdown = vm.optionsDropdown != value ? value : 0;
            }
        },
    }
</script>

<style scoped>
    .result_wpr.small .actions>ul {
        height: 35px;
    }

    .result_wpr.small .actions > ul li.search_area input[type=text] {
        height: 30px;
        width: 200px;
    }
    .result_wpr.small .actions > ul li.search_area .search_btn{
        height: 30px;
    }

    .result_wpr.small .actions>ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        /* font-size: 13px;
        line-height: 16px; */
    }

    .result_wpr.small table td .user_img,
    .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4 {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    table button {
        padding: 2px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 5px;
    }

    table button.failed {
        background: #f04438;
    }

    table button.success {
        background: #00aa14;
    }

    form, form .setting_wpr {
        height: calc(100% - 75px);
    }
</style>
